import { useState, useEffect } from 'react';
import { getCookieVal } from 'config/config';

// Add new cookie names here
type ValidCookies = 'apiKeysWithScope' | 'manualAnalysisEnabled';

enum Visibility {
  Show = '1',
  Hide = '0',
}

export default function useCookie(cookieName: ValidCookies) {
  const [cookie, setCookie] = useState<string | undefined>(undefined);

  useEffect(() => {
    setCookie(getCookieVal(cookieName));
  }, [cookieName]);

  return {
    show: cookie && cookie === Visibility.Show ? true : false,
  };
}
