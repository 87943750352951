import React, { useState } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import { useDispatch } from 'react-redux';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import { Dispatch } from 'state/types/thunk';
import { closeModal } from 'state/modal/actions';
import { useSelector } from 'react-redux';
import { ModalState } from 'state/modal';
import Modal from 'views/components/Modal';
import useFormBuilder from 'components/FormBuilder';
import Button from '@material-ui/core/Button/Button';

export type Fields = {
  external_team_account_number: string;
  external_email_address: string;
  scope: string[];
  tags: string[];
  custom_tags: string[];
  is_private: boolean;
  expiration_date: string;
};
type ModalProps = {
  onSubmit: (values: Fields) => void;
  filters: Fields;
};

const AddLimitedAccessModal = () => {
  const { classes } = useStyles();
  const _closeModal = () => dispatch(closeModal());
  const dispatch = useDispatch<Dispatch>();
  const { filters, onSubmit } = useSelector(
    ({ modal }: { modal: ModalState<ModalProps> }) => modal.params
  );
  const [errors, setErrors] = useState<string[]>([]);

  const { FormComponent } = useFormBuilder([
    {
      elementType: 'input',
      hideName: true,
      id: 'external_team_account_number',
      placeholder: 'Enter external team account number *',
    },
    {
      elementType: 'input',
      hideName: true,
      id: 'external_email_address',
      placeholder: 'Enter email address(es) of external team members *',
    },
    {
      elementType: 'selectTag',
      id: 'scope',
      name: 'Scope *',
      placeholder: 'Select which scopes to include',
      defaultOptions: ['Scope 1', 'Scope 2', 'Scope 3'],
      bold: false,
      hideName: true,
    },
    {
      elementType: 'selectTag',
      id: 'tags',
      name: 'Tags (Optional)',
      placeholder: 'Select existing tags',
      defaultOptions: ['Tag 1', 'Tag 2', 'Tag 3'],
      bold: false,
      hideName: true,
    },
    {
      elementType: 'inputTag',
      hideName: true,
      name: 'Custom Tags (Optional)',
      id: 'custom_tags',
      placeholder: 'Add custom tags',
    },
    {
      elementType: 'switch',
      name: 'Is Private',
      id: 'is_private',
      defaultValue: false,
      defaultOptions: ['Public', 'Private'],
      bold: false,
    },
    {
      elementType: { name: 'input', type: 'date' },
      hideName: true,
      name: 'Expiration Date',
      id: 'expiration_date',
      placeholder: 'Expiration Date',
    },
  ]);

  return (
    <Modal fullWidth={false} maxWidth='lg'>
      <div css={classes.container}>
        <h2 css={classes.title}>Add users with limited access this account</h2>
        <FormComponent
          customClass={classes.form}
          filters={filters}
          onSubmit={(values) => {
            const validationErrors: string[] = [];

            if (values.external_team_account_number === '') {
              validationErrors.push('Please enter external team account number');
            }
            if (values.external_email_address === '') {
              validationErrors.push('Please enter email address(es) of external team members');
            }
            if (!values.scope || values.scope.length === 0) {
              validationErrors.push('Please select which scopes to include');
            }

            setErrors(validationErrors);
            if (validationErrors.length) return;

            onSubmit(values);
          }}
          footerRender={({ dirtyFields }) => {
            return (
              <div style={{ width: '100%' }}>
                <div style={{ paddingBottom: '1rem' }}>
                  {errors.map((error) => (
                    <div key={error} style={{ color: 'red' }}>
                      {error}
                    </div>
                  ))}
                </div>
                <div css={classes.buttonsContainer}>
                  <Button
                    onClick={() => {
                      _closeModal();
                    }}
                    variant='outlined'
                    color='primary'
                    css={classes.button}
                  >
                    Cancel
                  </Button>
                  <Button type='submit' variant='contained' color='primary' css={classes.button}>
                    Submit
                  </Button>
                </div>
              </div>
            );
          }}
        />
      </div>
    </Modal>
  );
};

const useStyles = makeStyles({
  base: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: '3rem',
      width: 'max-content',
      padding: '3rem',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '1rem',
      width: '65rem',
    },
    title: {
      fontWeight: 600,
      fontSize: '2.3rem',
      textAlign: 'center',
    },
    buttonsContainer: {
      display: 'flex',
      gap: '1rem',
      justifyContent: 'flex-end',
    },
    button: {
      padding: '0.7rem 1rem !important',
    },
  },
  light: {},
  dark: {},
});

export default AddLimitedAccessModal;
