import React, { useEffect } from 'react';
import { useIntl, defineMessages } from 'react-intl';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import { RootState } from 'state/root';
import { PageQuery } from 'models/Page';
import { useSelector, useDispatch } from 'react-redux';
import { ApiKeyDataRes } from 'services/api/schema/apiKey';
import { extractPageQueryParams } from 'utils/pagination';
import { getApiKeys } from 'state/apiKeys/actions';
import { openModal } from 'state/modal/actions';
import warningImg from 'assets/images/warning.svg';
import PanelContent from 'views/components/layout/PanelContent';
import { useUser } from 'views/components/providers/UserProvider';
import ApiKeyTable from 'views/components/table/ApiKeyTable/ApiKeyTableContainer';
import { Fields } from 'views/pages/AccountPage/AccountPanel/AccountTabs/ApiKeysTab/modals/AddLimitedAccessModal';

const messages = defineMessages({
  warningText: {
    id: 'apiKeyTab.warningText',
    defaultMessage: 'Account must have 2FA enabled to proceed',
  },
  setup2fa: {
    id: 'apiKeyTab.setup2fa',
    defaultMessage: 'Setup 2FA',
  },
});

const ApiKeysTab = () => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const { apiKeys } = useSelector((state: RootState) => state);
  const { mfaEnabled } = useUser();

  const _getApiKeys = (refresh: boolean, query?: PageQuery<ApiKeyDataRes>) => {
    const params = Object.assign({}, extractPageQueryParams(apiKeys), query, {});
    dispatch(getApiKeys(params, refresh));
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const openAddLimitedAccessModal = () => {
    dispatch(
      openModal('ADD_LIMITED_ACCESS', {
        onSubmit: (values: Fields) => {
          // TODO: Implement backend logic
          console.log(values);
        },
        filters: {},
      })
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const openFilterModal = () => {
    dispatch(
      openModal('FILTER_TEAM_API_KEYS', {
        onSubmit: (values: any) => {
          // TODO: Implement filter logic
          console.log(values);
        },
        filters: {
          team: 'Any',
          team_account_number: 'Any',
          is_private: false,
        },
      })
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const openFilterApiKeyTagsModal = () => {
    dispatch(
      openModal('FILTER_API_KEY_TAGS', {
        // TODO: Replace with actual values
        onSubmit: (values: any) => {
          console.log(values);
        },
        filters: {
          tags: [],
        },
      })
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const openEditApiKeyTagsModal = () => {
    dispatch(
      openModal('EDIT_API_KEY_TAGS', {
        // TODO: Replace with actual values
        onSubmit: (values: any) => {
          console.log(values);
        },
        fields: {},
        email: 'test@polyswarm.io',
      })
    );
  };

  useEffect(() => {
    if (mfaEnabled) {
      _getApiKeys(true, { offset: 0 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!mfaEnabled) {
    return (
      <PanelContent
        heading={''}
        image={{
          src: warningImg,
          alt: intl.formatMessage(messages.warningText),
        }}
        text={intl.formatMessage(messages.warningText)}
        customButtons={[
          <Button
            component='button'
            color='primary'
            variant='contained'
            onClick={() => history.replace('/account/advanced')}
          >
            {intl.formatMessage(messages.setup2fa)}
          </Button>,
        ]}
      />
    );
  }
  return (
    <div style={{ padding: '0px 3rem 0px 0.5rem' }}>
      <ApiKeyTable
        isMfaEnabled={mfaEnabled}
        apiKeys={apiKeys}
        reloadApiKeys={() => _getApiKeys(true, { offset: 0 })}
      />
      <ApiKeyTable
        team
        isMfaEnabled={mfaEnabled}
        apiKeys={apiKeys}
        reloadApiKeys={() => _getApiKeys(true, { offset: 0 })}
      />
    </div>
  );
};

export default ApiKeysTab;
